@charset "UTF-8";
/* ----------
  共通
---------- */
.Common-Table {
  border: 1px solid #ddd;
  padding: 0.25rem 1rem;
  margin-bottom: 2rem;
  border-radius: 6px;
  white-space: nowrap;
  table-layout: fixed;
}

.Common-ButtonArea {
  display: flex;
  justify-content: right;
  padding: 1rem 0;
}

.Common-PaginationArea div {
  width: 33%;
}

.Common-PaginationArea {
  padding-top: var(--chakra-space-4);
  display: flex;
  justify-content: center;
  margin-bottom: var(--chakra-space-4);
  padding-top: 0.25rem;
}

.chakra-heading {
  font-weight: bold !important;
  font-size: 2rem !important;
  padding-bottom: 1rem !important;
}

tbody tr:hover {
  background-color: #ddd;
}

.DataCountArea {
  display: flex;
  justify-content: right;
  padding: 0 0 0.5rem 0;
}

.Logo {
  min-height: 30px;
  margin-left: 20px;
  width: 172px;
  margin-right: 10px;
}

/* ----------
  共通
  client-teacher specific shared styles will be added here
---------- */
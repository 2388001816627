.Lesson-Stat dl > div > div {
  padding-bottom: 15px;
  padding-left: 5px;
}

.Lesson-Stat dl > div:last-child {
  padding-bottom: unset;
}

.Lesson-Stat dl > div dd {
  padding-left: 5px;
}

.Lesson-Stat dl > div dd {
  padding-left: 5px;
}

.Lesson-Stat button {
  width: 10rem;
}
.qaModalBody div {
  padding-bottom: 10px;
}

.Lesson-ButtonArea {
  display: flex;
  justify-content: right;
  padding: 1rem 0;
}

.learner-DataCountArea {
  display: flex;
  justify-content: right;
  padding: 0 0 0.5rem 0;
}

.Lesson-Table {
  border: 1px solid #ddd;
  padding: 0.25rem 1rem;
  border-radius: 6px;
}

.Lesson-TableHead th {
  text-align: center;
}

.Lesson-TableHead th:nth-child(2) {
  text-align: center;
  width: 13rem;
}

.Lesson-TableHead th:first-child {
  border-left: none;
}

.Lesson-TableBody td {
  text-align: center;
}

/** ステータス **/
.Lesson-TableBody td:first-child {
  width: 5rem;
  border-left: none;
}



.Lesson-PaginationArea {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--chakra-space-4);
}

.Lesson-PaginationArea div {
  width: 33%;
}

.Lesson-PaginationArea div:last-child {
  display: flex;
  justify-content: right;
  padding-top: 0.25rem;
}

.Lesson-Table {
  border: 1px solid #ddd;
  padding: 0.25rem 1rem;
  border-radius: 6px;
}

.Lesson-TableHead th {
  text-align: center;
}

.Lesson-TableHead th:nth-child(2) {
  text-align: center;
  width: 13rem;
}

.Lesson-TableHead th:first-child {
  border-left: none;
}

.Lesson-TableBody td {
  text-align: center;
}

/** ID **/
.Lesson-TableBody td:first-child {
  border-left: none;
}

/** 登録日 **/
.Lesson-TableBody td:nth-child(2) {
  width: 16rem;
}

/** アクション **/
.Lesson-TableBody td:nth-child(5) {
  width: 2rem;
}

.Lesson-PaginationArea {
  display: flex;
  justify-content: center;
  margin-bottom: var(--chakra-space-4);
  padding-top: 0.25rem;
}

.Lesson-PaginationArea div {
  width: 33%;
}

.LessonNotes-details,
.LessonNotes-details-readOnly {
  min-height: 100px;
  overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  border: 1px solid #ddd;
  padding: 8px;
}

.LessonNotes-details {
  white-space: pre-wrap;
}

.LessonNotes-details-readOnly {
  color: #CBD5E0;
}

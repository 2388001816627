.Options-Stat dl > div > div {
  width: 50%;
  padding-bottom: 15px;
  padding-left: 5px;
}

.Options-Stat dl > div:last-child {
  padding-bottom: unset;
}

.Options-Stat dl > div dd {
  padding-left: 5px;
}

.Options-Stat dl > div dd {
  padding-left: 5px;
}

.Options-Stat button {
  width: 10rem;
}
.qaModalBody div {
  padding-bottom: 10px;
}

.Options-ButtonArea {
  display: flex;
  justify-content: right;
  padding: 1rem 0;
}

.learner-DataCountArea {
  display: flex;
  justify-content: right;
  padding: 0 0 0.5rem 0;
}

.Options-Table {
  border: 1px solid #ddd;
  padding: 0.25rem 1rem;
  border-radius: 6px;
}

.Options-TableHead th {
  text-align: center;
}

.Options-TableHead th:nth-child(2) {
  text-align: center;
}

.Options-TableHead th:first-child {
  border-left: none;
}

.Options-TBody td {
  text-align: center;
}

/** 項目 **/
.Options-TBody td:first-child {
  border-left: none;
}



.Options-PaginationArea {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--chakra-space-4);
}

.Options-PaginationArea div {
  width: 33%;
}

.Options-PaginationArea div:last-child {
  display: flex;
  justify-content: right;
  padding-top: 0.25rem;
}

.Options-Table {
  border: 1px solid #ddd;
  padding: 0.25rem 1rem;
  border-radius: 6px;
}

.Options-TableHead th {
  text-align: center;
}

.Options-TableHead th:nth-child(2) {
  text-align: center;
}

.Options-TableHead th:first-child {
  border-left: none;
}

.Options-TableBody td {
  text-align: center;
}

/** ID **/
.Options-TableBody td:first-child {
  border-left: none;
}

/** 登録日 **/
.Options-TableBody td:nth-child(2) {
  width: 16rem;
}

/** アクション **/
.Options-TableBody td:nth-child(5) {
  width: 2rem;
}

.Options-PaginationArea {
  display: flex;
  justify-content: center;
  margin-bottom: var(--chakra-space-4);
  padding-top: 0.25rem;
}

.Options-PaginationArea div {
  width: 33%;
}

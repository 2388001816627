.Learner-Stat dl > div > div {
  width: 50%;
  padding-bottom: 15px;
  padding-left: 5px;
}

.table-tiny td {
  padding-left: 22px;

  
}
.Learner-Stat dl > div:last-child {
  padding-bottom: unset;
}

.Learner-Stat dl > div dd {
  padding-left: 5px;
}

.Learner-Stat dl > div dd {
  padding-left: 5px;
}

.Learner-Stat button {
  width: 6rem;
}
.qaModalBody div {
  padding-bottom: 10px;
}

.Learner-ButtonArea {
  display: flex;
  justify-content: right;
  padding: 1rem 0;
}

.learner-DataCountArea {
  display: flex;
  justify-content: right;
  padding: 0 0 0.5rem 0;
}

.Learner-Table {
  border: 1px solid #ddd;
  padding: 0.25rem 1rem;
  border-radius: 6px;
}

.Learner-TableHead th {
  text-align: center;
}

.Learner-TableHead th:nth-child(2) {
  text-align: center;
  width: 13rem;
}

.Learner-TableHead th:first-child {
  border-left: none;
}

.Learner-TableBody td {
  text-align: center;
  cursor: pointer;
}

/** ステータス **/
.Learner-TableBody td:first-child {
  width: 5rem;
  border-left: none;
}



.Learner-PaginationArea {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--chakra-space-4);
}

.Learner-PaginationArea div {
  width: 33%;
}

.Learner-PaginationArea div:last-child {
  display: flex;
  justify-content: right;
  padding-top: 0.25rem;
}

.Learner-Table {
  border: 1px solid #ddd;
  padding: 0.25rem 1rem;
  border-radius: 6px;
}

.Learner-TableHead th {
  text-align: center;
}

.Learner-TableHead th:nth-child(2) {
  text-align: center;
  width: 13rem;
}

.Learner-TableHead th:first-child {
  border-left: none;
}

.Learner-TableBody td {
  text-align: center;
}

/** ID **/
.Learner-TableBody td:first-child {
  /* width: 6rem; */
  border-left: none;
}

/** 登録日 **/
.Learner-TableBody td:nth-child(2) {
  width: 16rem;
}

/** アクション **/
.Learner-TableBody td:nth-child(5) {
  width: 2rem;
}

.Learner-PaginationArea {
  display: flex;
  justify-content: center;
  margin-bottom: var(--chakra-space-4);
  padding-top: 0.25rem;
}

.Learner-PaginationArea div {
  width: 33%;
}

.css-gvk116 {
  padding-left: 5px;
}

.LessonNotes-details,
.LessonNotes-details-readOnly,
.Feedback-details,
.Feedback-details-readOnly {
  min-height: 100px;
  overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  border: 1px solid #ddd;
  padding: 8px;
}

.LessonNotes-details,
.Feedback-details {
  white-space: pre-wrap;
}

.LessonNotes-details-readOnly,
.Feedback-details-readOnly {
  color: #CBD5E0;
}